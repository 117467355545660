// WalletProvider.js
import React from "react";
import {
	ConnectionProvider,
	WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
	PhantomWalletAdapter,
	SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

export const WalletConnectionProvider = ({ children }) => {
	// Network beállítása mainnet-re
	const network = WalletAdapterNetwork.Mainnet;

	// A pénztárca adapterek inicializálása
	const wallets = [
		new PhantomWalletAdapter(),
		//new SolflareWalletAdapter({ network }),
	];

	return (
		<ConnectionProvider endpoint="https://api.mainnet-beta.solana.com">
			<WalletProvider wallets={wallets} autoConnect>
				<WalletModalProvider>{children}</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	);
};

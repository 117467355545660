const axios = require("axios");
const https = require("https");
//import https from "https";
//const SERVER = "http://localhost:30777";
const SERVER = "https://api.readyswap.io:30777";

const axiosI = axios.create({
	baseURL: SERVER,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	credentials: "include",
	mode: "cors",
	httpsAgent: new https.Agent({
		rejectUnauthorized: false,
	}),
	withCredentials: true,
});

//module.exports = axiosI;
export default axiosI;

import { useState, useEffect } from "react";
import logo from "./media/logo.png";
import axiosI from "./api/api";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import {
	Connection,
	PublicKey,
	Transaction,
	TransactionInstruction,
	clusterApiUrl,
} from "@solana/web3.js";
import { toast } from "react-toastify";
import RevShare from "./components/RevShare";
import Referral from "./components/Referral";
import Tuf from "./components/Tuf";
import { Web3Modal, useWeb3ModalTheme } from "@web3modal/react";
const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
const baseRevData = {
	address: "",
	tokens: "",
	boost: 0,
	sol: "",
	all_sol: "",
	all_claimed: "",
	total_claimed: "",
	rev_share_perc: "",
};
const baseInfo = { total_dist: "" };
let intervalSID;
let l = { ts: new Date().getTime(), msg: "", type: "" };
const messageSender = (type, msg) => {
	if (
		l.type === type &&
		l.msg === msg &&
		l.ts + 1000 > new Date().getTime()
	) {
		l.type = type;
		l.msg = msg;
		l.ts = new Date().getTime();
	} else {
		l.type = type;
		l.msg = msg;
		l.ts = new Date().getTime();
		switch (type) {
			case "success":
				toast.success(msg);
				break;
			case "error":
				toast.error(msg);
				break;
			case "warning":
				toast.warning(msg);
				break;
			default:
				break;
		}
	}
};

function App() {
	const [session, setSession] = useState("");
	const [menu, setMenu] = useState(1);
	const [activeClass, setActiveClass] = useState("active active_revenue");
	const { connected, publicKey, signMessage, signTransaction } = useWallet();

	const [revData, setRevData] = useState(baseRevData);
	const [revHistory, setRevHistory] = useState([]);
	const [tufHistory, setTufHistory] = useState([]);
	const [airdrop, setAirdrop] = useState();
	const [tufWallet, setTufWallet] = useState();
	const [loader, setLoader] = useState(false);
	const [info, setInfo] = useState(baseInfo);
	const [checkedLedger, setCheckedLedger] = useState(false);

	const { theme, setTheme } = useWeb3ModalTheme();
	useEffect(() => {
		setTheme({
			themeMode: "dark",
			themeVariables: {
				"--w3m-font-family": "Poppins, sans-serif",
				"--w3m-accent-color": "#30bd92",
				"--w3m-background-color": "#1a1a1a",
				"--w3m-z-index": "9999",
			},
		});
	}, []);
	useEffect(() => {
		if (!!publicKey) {
			auth_session();
		} else {
			if (!!!intervalSID) {
				clearInterval(intervalSID);
			}
			setDefault();
		}
	}, [publicKey]);
	useEffect(() => {
		if (!!menu && !!connected && !!session && !!publicKey) {
			//signAuthMessage();
			getWalletData();
		}
	}, [connected, menu]);

	useEffect(() => {
		get_info();
	}, []);

	const setDefault = () => {
		setRevData(baseRevData);
		setRevHistory([]);
		setTufHistory([]);
		setAirdrop();
	};
	const getWalletData = () => {
		switch (menu) {
			case 1:
				getRevenueData();
				getRevHistoryData();
				break;
			case 2:
				getReferralData();
				break;
			/* case 3:
				checkTUFTokens(tufWallet);
				break; */

			default:
				break;
		}
	};
	const ping = () => {
		//auth_sid();
		intervalSID = setInterval(() => {
			auth_sid();
		}, 12 * 1000);
	};
	const auth_session = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/session", {
				publicKey: publicKey.toBase58(),
			})
			.then(async (res) => {
				try {
					clearInterval(intervalSID);
					setSession(res.data.data);
					await auth_sid();
					ping();

					getWalletData();
				} catch (error) {}
			})
			.catch((error) => {
				setSession("");
				try {
					//toast.error(error.response.data.error.message);
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
	};
	const get_info = () => {
		console.log("get_info");
		axiosI
			.post("/api/info", {
				publicKey: "",
			})
			.then(async (res) => {
				try {
					setInfo(res.data.data);
				} catch (error) {}
			})
			.catch((error) => {
				setSession("");
				try {
					//toast.error(error.response.data.error.message);
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
	};
	const auth_sid = async () => {
		try {
			if (!!!publicKey) return;
			await axiosI.post("/api/sid", {
				publicKey: publicKey.toBase58(),
			});
			return true;
		} catch (error) {}
	};
	const getRevenueData = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/get_revenue_data", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
					setRevData(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setRevData(baseRevData);
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							messageSender(
								"error",
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							messageSender(
								"error",
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
	};
	const getRevHistoryData = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/get_rev_history", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
					setRevHistory(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setRevHistory([]);
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							messageSender(
								"error",
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							messageSender(
								"error",
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
	};
	const getReferralData = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/get_referral_data", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
				} catch (error) {}
			})
			.catch(async (error) => {
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							messageSender(
								"error",
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							messageSender(
								"error",
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
	};
	const getTufData = () => {
		/* axiosI
			.post("/api/get_tuf_data", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
					console.log("res", res);
				} catch (error) {}
			})
			.catch(async (error) => {
				if (!!error.response.data) {
					if (error.response.data.error.code == 9) {
						toast.error(
							"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
						);
					} else if (error.response.data.error.code == 5) {
						toast.error("Network error! Please refresh the site.");
					}
				}
			}); */
	};

	const claimRev = async () => {
		try {
			if (!!!publicKey) return;
			setLoader(true);
			const sessionS = await axiosI.post("/api/claim_session", {
				publicKey: publicKey.toBase58(),
			});
			let claimSession = sessionS.data.data;
			if (!!!claimSession) throw "Session error";

			let signature;
			let url = "/api/claim_rev";

			if (checkedLedger) {
				signature = await signAuthMessageLedger(claimSession);
				if (!!!signature) throw "signature error";
				url = "/api/claim_rev_tx";
			} else {
				signature = await signAuthMessage(claimSession);
				if (!!!signature) throw "signature error";
			}

			axiosI
				.post(url, {
					publicKey: publicKey.toBase58(),
					session: claimSession,
					signature,
				})
				.then((res) => {
					try {
						messageSender("success", "Successful SOL Claim");
						getWalletData();
						setLoader(false);
					} catch (error) {}
				})
				.catch(async (error) => {
					setLoader(false);
					try {
						if (!!error.response.data) {
							if (error.response.data.error.code == 9) {
								messageSender(
									"error",
									"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
								);
							} else if (error.response.data.error.code == 5) {
								messageSender(
									"error",
									"Network error! Please refresh the site."
								);
							} else {
								messageSender(
									"error",
									error.response.data.error.message
								);
							}
						}
					} catch (err) {
						messageSender(
							"error",
							"Network error! Please refresh the site."
						);
					}
				});
		} catch (error) {
			messageSender("error", "Claim error!");
			setLoader(false);
		}
	};
	const signAuthMessage = async (session) => {
		const message = new TextEncoder().encode(
			"Please sign this message to be able to claim your Revenue shares.\n\n\n" +
				session
		);
		try {
			//const signature = await signIn(message);
			const signature = await signMessage(message);

			return signature;
		} catch (error) {
			console.error("Signing failed", error);
			return false;
		}
	};
	const signAuthMessageLedger = async (session) => {
		try {
			const block = await axiosI.post("/api/claim_tx", {
				publicKey: publicKey.toBase58(),
				session,
			});

			const MEMO_PROGRAM_ID = new PublicKey(
				"MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
			);
			const messageString =
				"Please sign this message to be able to claim your Revenue shares.\n\n\n" +
				session;
			const message = new TextEncoder().encode(messageString);
			const buildAuthTx = async (nonce) => {
				const tx = new Transaction();

				tx.add(
					new TransactionInstruction({
						programId: MEMO_PROGRAM_ID,
						keys: [],
						data: Buffer.from(nonce, "utf8"),
					})
				);
				return tx;
			};

			const tx = await buildAuthTx(message);
			tx.feePayer = publicKey;
			tx.recentBlockhash = block.data.data;

			const signedTx = await signTransaction(tx);
			const serializedTx = signedTx.serialize();

			const sign = Array.from(serializedTx);
			return sign;
		} catch (error) {
			console.log("error", error);
		}
	};

	/**TUF */
	const checkTUFTokens = (address) => {
		return;
		if (!!!publicKey || !!!address) return;
		axiosI
			.post("/api/tuf", {
				publicKey: publicKey.toBase58(),
				wallet: address,
			})
			.then((res) => {
				try {
					//console.log("tuf", res.data.data);
					setAirdrop(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setAirdrop();
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							messageSender(
								"error",
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							messageSender(
								"error",
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
		getTufHistoryData(address);
	};
	const getTufHistoryData = (address) => {
		return;
		if (!!!publicKey || !!!address) return;
		axiosI
			.post("/api/get_tuf_history", {
				publicKey: publicKey.toBase58(),
				wallet: address,
			})
			.then((res) => {
				try {
					setTufHistory(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setTufHistory([]);
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							messageSender(
								"error",
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							messageSender(
								"error",
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					messageSender(
						"error",
						"Network error! Please refresh the site."
					);
				}
			});
	};
	const claimTUFSession = async (address) => {
		try {
			return;
			if (!!!publicKey || !!!address) return;
			const sessionS = await axiosI.post("/api/claim_tuf_session", {
				publicKey: publicKey.toBase58(),
				wallet: address,
			});
			let claimSession = sessionS.data.data;
			if (!!!claimSession) throw "Session error";

			return claimSession;
		} catch (error) {
			messageSender("error", "Network error! Please refresh the site.");
			return false;
		}
	};
	const claimTuf = async (signature, address, session) => {
		try {
			return;
			if (!!!publicKey || !!!address) return;
			setLoader(true);
			axiosI
				.post("/api/claim_tuf", {
					publicKey: publicKey.toBase58(),
					session: session,
					signature,
					wallet: address,
				})
				.then((res) => {
					try {
						messageSender("success", "Success Claim");
						checkTUFTokens(address);
						setLoader(false);
					} catch (error) {}
				})
				.catch(async (error) => {
					try {
						setLoader(false);
						if (!!error.response.data) {
							if (error.response.data.error.code == 9) {
								messageSender(
									"error",
									"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
								);
							} else if (error.response.data.error.code == 5) {
								messageSender(
									"error",
									"Network error! Please refresh the site."
								);
							} else {
								messageSender(
									"error",
									error.response.data.error.message
								);
							}
						}
					} catch (err) {
						messageSender(
							"error",
							"Network error! Please refresh the site."
						);
					}
				});
		} catch (error) {
			setLoader(false);
			messageSender("error", "Network error! Please refresh the site.");
			return false;
		}
	};

	const clickMenu = (menu_) => {
		setMenu(menu_);
		switch (menu_) {
			case 1:
				setActiveClass("active active_revenue");
				break;
			case 2:
				setActiveClass("active active_referral");
				break;
			/* case 3:
				setActiveClass("active active_tuf");
				break; */

			default:
				setActiveClass("active active_revenue");
				break;
		}
	};

	return (
		<>
			<div
				className="header-wrapper"
				data-aos="fade-down"
				data-aos-duration="1300"
				style={{ position: "relative" }}
			>
				<header>
					<a
						href="https://readyswap.io"
						className="header-logo-wrapper"
					>
						<img className="header-logo" src={logo} />
						<div className="readyswap">
							Ready<span className="smaller">Swap</span>
						</div>
					</a>
					<div className="wallet">
						<WalletMultiButton
							labels={{ "no-wallet": "Connect Wallet" }}
						/>
					</div>
				</header>
			</div>
			<section className="switch-button-wrapper">
				<div className="switch-button">
					<span className={activeClass}></span>
					<button
						className={
							menu === 1
								? "switch-button-case left active-case "
								: "switch-button-case left"
						}
						onClick={() => clickMenu(1)}
					>
						Revenue Share
					</button>

					<button
						className={
							menu === 2
								? "switch-button-case middle active-case "
								: "switch-button-case middle"
						}
						style={{ color: "grey" }}
					>
						Referral Rewards
					</button>

					{/*<button
						className={
							menu === 3
								? "switch-button-case right active-case "
								: "switch-button-case right"
						}
						onClick={() => clickMenu(3)}
					>
						$TUF Airdrop Claim
					</button>*/}
				</div>
			</section>
			<section>
				{menu === 1 && (
					<RevShare
						revData={revData}
						revHistory={revHistory}
						claimRev={claimRev}
						loader={loader}
						info={info}
						checkedLedger={checkedLedger}
						setCheckedLedger={setCheckedLedger}
					/>
				)}
				{menu === 2 && <Referral />}
				{/*menu === 3 && (
					<Tuf
						checkTUFTokens={checkTUFTokens}
						airdrop={airdrop}
						tufHistory={tufHistory}
						claimTUFSession={claimTUFSession}
						claimTuf={claimTuf}
						tufWallet={tufWallet}
						setTufWallet={setTufWallet}
						loader={loader}
					/>
				)*/}
			</section>
		</>
	);
}

export default App;

import React from "react";
import ReactDOM from "react-dom/client";
// Szükséges stílusok importálása
import "@solana/wallet-adapter-react-ui/styles.css";
import "./main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WalletConnectionProvider } from "./components/WalletConnectionProvider";

import {
	EthereumClient,
	w3mConnectors,
	w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3ModalTheme } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";

localStorage.setItem("wagmi.injected.shimDisconnect", "true");
const chains = [mainnet];
const projectId = "e34afb9cba0d97c7c171d01a7194615b";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({ projectId, version: 1, chains }),
	publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<WalletConnectionProvider>
		<ToastContainer
			theme="dark"
			position="bottom-right"
			autoClose={4000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			style={{ width: "400px" }}
		/>
		<WagmiConfig config={wagmiConfig}>
			<App />
		</WagmiConfig>
		<Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
	</WalletConnectionProvider>
);

reportWebVitals();

import React from "react";

const Referral = () => {
	return (
		<div className="referral-rewards">
			<div className="dashboard-top">
				<div className="information">
					<span className="text">Your Referral Link</span>
					<span className="number">--</span>
				</div>
				<div className="information">
					<span className="text">Referred Wallets</span>
					<span className="number">--</span>
				</div>
			</div>
			<div className="dashboard-wrapper">
				<div className="dashboard-left">
					<h1>Referral Rewards</h1>
					<span>
						Earn 15% of trading fees generated by those who sign up
						via your referral link. Minimum referral rewards claim
						amount is 0.01 SOL. Rewards are only claimable once
						every 24 hours. The referral code only works with new
						user registration. Adding a new trading wallet does not
						count!
					</span>
				</div>
				<div className="dashboard-right">
					<div className="user-information-row">
						<span className="text">Lifetime Claimed Rewards</span>
						<span className="number">--</span>
					</div>
					<div className="user-information-row">
						<span className="text">Unclaimed Rewards</span>
						<span className="number">--</span>
					</div>
					<button id="claim-button">Claim</button>
				</div>
			</div>
			<div className="claim-history">
				<h2>Claim History</h2>
				<div className="claim-history-table">
					<div className="table-row title">
						<span>Time</span>
						<span>Amount</span>
						<span>Transaction</span>
					</div>
					<div className="table-row">
						<span>xx</span>
						<span>100</span>
						<span>transaction</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Referral;
